import React, { useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import qs from 'qs';
import { navigate } from 'gatsby';
import StorageService from '../../../services/storage.service';
import {
  LOCALSTORAGE_KEYS,
  LOCALSTORAGE_PRICE_KEYS,
} from '../../../constans/localstorage';
import {
  activityRatingAgeInterestOptions,
  activityRatingInterestInputs,
  activitySearchProfileInterestsInitialValues,
  activitySearchProfilePriorityInitialValues,
  getSearchProfile,
  landingImportanceInputs,
} from '../../../constans/acitivity-rating-profile';
import HomeTabSection from '../HomeTabSection/HomeTabSection';
import HomeTabCard from '../HomeTabCard/HomeTabCard';
import ExploreDestinationCard from '../../ExploreDestination/ExploreDestinationCard';
import AdjustInterests from '../../AdjustInterests/AdjustInterests';
import SelectDestinationRedesign from '../../SelectDestinationRedesign/SelectDestinationRedesign';
import Button from '../../UI/Button/Button';
import Priorities from '../../Priorities/Priorities';
import ActivityType from '../../ActivityType/ActivityType';
import { ACTIVITY_CATEGORY_OPTIONS } from '../../../constans/activity';
import BudgetCard from '../../Budget/BudgetCard';
import ActivitiesService from '../../../services/activitiesService';
import {
  AccessibilityAndKidsOptions,
  FitnessRequirementsOptions,
} from '../../../constans/itineraries-search';
import GuestsInputRedesigned from '../../GuestsInputRedesigned';
import {
  BUDGET_PROPERTY,
  budgetFieldsInitialValues,
  getPriceRangeFromURL,
} from '../../../constans/budget';
import {
  filterObjectByKeys,
  removeEmptyObjectKeys,
} from '../../../helpers/helper-methods';
import { ActivitySearchProfileKeys } from '../../../constans/activity-search';
import { activitySearchSchema } from '../../../constans/validation-schemas';
import LandingTabSpinner from '../../LandingTabSpinner/LandingTabSpinner';

import styles from './ActivityTab.module.scss';
import { STORAGE_DESTINATION } from '../../DestinationTree';
import { getFullLocationName } from '../../../helpers/generateLinks';

function ActivityTab({
  handleSubmitForm,
  setTab,
  initValues,
  withInfo = false,
}) {
  const [isSearching, setIsSearching] = useState(false);
  const [destination, setDestination] = useState();
  const activitiesService = useMemo(() => ActivitiesService(), []);
  const setIsSearchingDebounced = (state) =>
    setTimeout(() => setIsSearching(state), 300);

  const { ...defaultCurrency } = budgetFieldsInitialValues;

  const storage = StorageService();
  const searchDates = useMemo(
    () => storage.get(LOCALSTORAGE_KEYS.SEARCH_DATES),
    [storage]
  );

  const minActivityPrice = storage.get(LOCALSTORAGE_PRICE_KEYS.MIN_PRICE, null);
  const maxActivityPrice = storage.get(LOCALSTORAGE_PRICE_KEYS.MAX_PRICE, null);

  const formInitData = useMemo(
    () => ({
      adults: initValues?.adults || 2,
      kids: initValues?.kids || 0,
      locationName:
        initValues?.locationName ||
        StorageService().get(STORAGE_DESTINATION)?.name,
      countryId: initValues?.countryId || null,
      regionId: initValues?.regionId || null,
      cityId: initValues?.cityId || null,
      districtId: initValues?.districtId || null,
      ageOfKids: [],
      ageOfAdults: ['', ''],
      categoriesFilters: [],
      accessibilityScore: AccessibilityAndKidsOptions[0].value,
      fitnessRequirementScore: FitnessRequirementsOptions[0].value,
      kidsAllowedScore: AccessibilityAndKidsOptions[0].value,
      checkin: searchDates?.checkin || initValues?.checkin || '',
      checkout: searchDates?.checkout || initValues?.checkout || '',
      q: '',
      ...defaultCurrency,
      ...StorageService().get(LOCALSTORAGE_KEYS.ACTIVITY_SEARCH),
      ...getPriceRangeFromURL(initValues, minActivityPrice, maxActivityPrice),
    }),
    [searchDates]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSearchingDebounced(true);
    setSubmitting(true);

    const {
      locationName,
      currency,
      kids,
      adults,
      ageOfAdults,
      minPrice,
      ageOfKids,
      categoriesFilters,
      fitnessRequirementScore,
      kidsAllowedScore,
      accessibilityScore,
      // do not send, backend throw error for this -.-
      checkin,
      checkout,
      //
      ...requestValues
    } = values;

    const guestsAges = [...ageOfAdults, ...ageOfKids].map((age) =>
      age.toString()
    );
    let userProfile = filterObjectByKeys(
      getSearchProfile(),
      ActivitySearchProfileKeys
    );
    const filters = categoriesFilters.length ? categoriesFilters : null;

    if (
      !userProfile.culturalAndDiscovery ||
      !userProfile.outdoorsAndPhysicalActivities ||
      !userProfile.beachAndSeaside ||
      !userProfile.entertainmentAndThemeParks ||
      !userProfile.cityDiscoveryAndShopping
    ) {
      userProfile = {
        ...activitySearchProfileInterestsInitialValues,
        ...userProfile,
      };
    }

    if (
      !userProfile.importanceOfPrice ||
      !userProfile.experienceIntensity ||
      !userProfile.comfortAndService ||
      !userProfile.locationAndAccess ||
      !userProfile.foodAndBeverages ||
      !userProfile.environmentalAndSocial
    ) {
      userProfile = {
        activitySearchProfilePriorityInitialValues,
        ...userProfile,
      };
    }

    const model = {
      ...requestValues,
      currency,
      guestsAges,
      userProfile,
      ...(filters?.length && { categoriesFilters: filters }),
    };
    delete model.priceType;
    const {
      data: { uuid },
    } = await activitiesService.getActivitySearchUUID({
      ...removeEmptyObjectKeys(model),
      minPrice,
      kidsAllowedScore,
      accessibilityScore,
      fitnessRequirementScore: parseFloat(fitnessRequirementScore) || 0,
    });

    const redirectQuery = qs.stringify({
      uuid,
      locationName,
      currency,
      kids,
      adults,
      ...(filters?.length && { categoriesFilters: filters }),
    });

    setSubmitting(false);
    StorageService().set(LOCALSTORAGE_KEYS.ACTIVITY_SEARCH, values);
    navigate(`/activities/search/results?${redirectQuery}`);
  };

  const handleSearch = (values, formikHelpers) => {
    handleSubmit(values, formikHelpers)
      // .then(() => setIsSearchingDebounced(false)) //fixme: it causes a memory leak
      .catch(() => setIsSearchingDebounced(false));
  };

  const storageDestination = StorageService().get(STORAGE_DESTINATION);

  const onClickFindTheBest = () => {
    setTab();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onClickFindTheBestButton',
    });
  };

  return (
    <>
      <LandingTabSpinner show={isSearching} />
      <Formik
        enableReinitialize
        validateOnBlur
        validateOnChange
        initialTouched={{
          [BUDGET_PROPERTY.maxPrice.name]: true,
          [BUDGET_PROPERTY.minPrice.name]: true,
        }}
        initialValues={{
          ...formInitData,
          locationName: storageDestination?.country?.name
            ? getFullLocationName(storageDestination)
            : '',
        }}
        validationSchema={activitySearchSchema}
        onSubmit={handleSearch}
      >
        {({
          setFieldValue,
          values,
          errors,
          initialValues,
          isValid,
          setFieldTouched,
          touched,
        }) => {
          const changeMathValue = (field, value, direction) => {
            setFieldValue(field, direction ? value + 1 : value - 1);
          };

          StorageService().set(
            LOCALSTORAGE_PRICE_KEYS.MIN_PRICE,
            values.minPrice
          );
          StorageService().set(
            LOCALSTORAGE_PRICE_KEYS.MAX_PRICE,
            values.maxPrice
          );

          return (
            <Form>
              <HomeTabSection>
                <HomeTabCard title="Destination">
                  <SelectDestinationRedesign
                    onSelect={setDestination}
                    classes={`${styles.wrapper}`}
                    isRegionRequired
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    initialValue={
                      initialValues?.countryId
                        ? {
                            name:
                              initialValues?.locationName ||
                              values?.locationName,
                            country: initialValues?.countryId || {
                              id: values?.countryId,
                            },
                            region: initialValues?.regionId || {
                              id: values?.regionId,
                            },
                            city: initialValues?.cityId || {
                              id: values?.cityId,
                            },
                            district: initialValues?.districtId || {
                              id: values?.districtId,
                            },
                          }
                        : {
                            name:
                              getFullLocationName(storageDestination) ||
                              values?.locationName,
                            country: storageDestination?.country || {
                              id: values?.countryId,
                            },
                            region: storageDestination?.region || {
                              id: values?.regionId,
                            },
                            city: storageDestination?.city || {
                              id: values?.cityId,
                            },
                            district: storageDestination?.district || {
                              id: values?.districtId,
                            },
                          }
                    }
                    error={errors.locationName}
                    touched={touched.locationName}
                  />
                </HomeTabCard>
                <HomeTabCard
                  contentClassName="w-100"
                  title="# Travellers"
                  divider
                >
                  <GuestsInputRedesigned
                    setFieldValue={setFieldValue}
                    showErrors
                    errors={errors}
                    touched={touched}
                    values={values}
                    changeMathValue={changeMathValue}
                    adultsCountInputName="adults"
                    kidsCountInputName="kids"
                    adultAgeInputName="ageOfAdults"
                    kidsAgeInputName="ageOfKids"
                    minAdultsCount={0}
                    setFieldTouched={setFieldTouched}
                    divider
                  />
                </HomeTabCard>
                <BudgetCard
                  divider
                  values={values}
                  setFieldValue={setFieldValue}
                  enablePriceType={false}
                  errors={errors}
                />
                <AdjustInterests
                  handleSubmit={handleSubmitForm}
                  inputs={activityRatingInterestInputs}
                  ageInputs={activityRatingAgeInterestOptions}
                  initialValues={activitySearchProfileInterestsInitialValues}
                  divider
                  hideHeader
                  tooltipField="comfortAndService"
                />
              </HomeTabSection>
              {!withInfo && (
                <ExploreDestinationCard
                  divider
                  activities
                  handleSubmit={handleSubmitForm}
                  destination={destination}
                  categoriesFilters={values.categoriesFilters}
                  setTab={setTab}
                  storageDestination={storageDestination}
                />
              )}
              <HomeTabSection
                className={`${styles.filtersPanel} ${
                  withInfo && styles.withInfo
                }`}
              >
                <div className={`${styles.filtersButtonContainer}`}>
                  <Priorities
                    inputs={landingImportanceInputs}
                    initialValues={activitySearchProfilePriorityInitialValues}
                    handleSubmit={handleSubmitForm}
                  />
                  <ActivityType
                    inputs={ACTIVITY_CATEGORY_OPTIONS}
                    initialValues={{
                      categoriesFilters: initialValues.categoriesFilters,
                    }}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>

                <Button
                  onClick={onClickFindTheBest}
                  disabled={!destination?.locationName || !isValid}
                  type="submit"
                  classes={`${styles.submitButton} t-600 btn btn_primary-danger uppercased-text`}
                >
                  Find The Best
                </Button>
              </HomeTabSection>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ActivityTab;
