export const TabTypes = {
  itinerary: 0,
  activity: 1,
  stay: 2,
};
export const urlSearchParams = {
  type: 'type',
  checkin: 'checkin',
  checkout: 'checkout',
  locationName: 'locationName',
  cityId: 'cityId',
  countryId: 'countryId',
  districtId: 'districtId',
  regionId: 'regionId',
  adults: 'adults',
  kids: 'kids',
  rooms: 'rooms',
  maxPrice: 'maxPrice',
  minPrice: 'minPrice',
};
