import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import HomeTabCard from '../Home/HomeTabCard/HomeTabCard';
import Button from '../UI/Button/Button';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import AdjustInterestsInputs from '../Users/SearchProfile/AdjustInterestsInputs/AdjustInterestsInputs';
import AdjustInterestsAgeInput from '../Users/SearchProfile/AdjustInterestsAgeInput/AdjustInterestsAgeInput';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import StorageService from '../../services/storage.service';

const AdjustInterests = ({
  handleSubmit,
  divider,
  inputs,
  ageInputs,
  initialValues,
  sliderLabelClassName = 'col-md-5 ',
  sliderClassName = 'col-md-7',
  radioLabelClassName = 'col-sm-2 p-0 flex-v-center',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const getProfile = () => {
    const { searchProfile } = StorageService().get(LOCALSTORAGE_KEYS.USER);

    return { ...initialValues, ...searchProfile };
  };

  return (
    <HomeTabCard title="Your interests" divider={divider}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={getProfile()}
        onSubmit={handleSubmit}
      >
        {({ resetForm, dirty }) => (
          <Form>
            <HomeTabCardModal
              title="Adjust interests"
              show={isModalOpen}
              displayToggle={toggleModal}
              onClickResetButton={resetForm}
              onClickSubmitButton={toggleModal}
              resetDisable={!dirty}
              buttonType="submit"
            >
              <AdjustInterestsInputs
                inputs={inputs}
                max={4}
                sliderClassName={sliderClassName}
                sliderLabelClassName={sliderLabelClassName}
              />
              <AdjustInterestsAgeInput
                radioLabelClassName={radioLabelClassName}
                options={ageInputs}
              />
            </HomeTabCardModal>
            <Button type="button" onClick={toggleModal} classes="flex-shrink-0 t-600 btn btn_primary-link-light p-0 ml-1"> Adjust interests </Button>
          </Form>
        )}
      </Formik>
    </HomeTabCard>
  );
};

export default AdjustInterests;
