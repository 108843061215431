import React, { useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import qs from 'qs';
import { navigate } from 'gatsby';
import { object, string } from 'yup';
import StorageService from '../../../services/storage.service';
import {
  LOCALSTORAGE_KEYS,
  LOCALSTORAGE_PRICE_KEYS,
} from '../../../constans/localstorage';
import {
  activityRatingAgeInterestOptions,
  activityRatingInterestInputs,
  activitySearchProfileInterestsInitialValues,
  activitySearchProfilePriorityInitialValues,
  getSearchProfile,
  landingImportanceInputs,
} from '../../../constans/acitivity-rating-profile';
import HomeTabSection from '../HomeTabSection/HomeTabSection';
import GuestsInputRedesigned from '../../GuestsInputRedesigned';
import HomeTabCard from '../HomeTabCard/HomeTabCard';
import ExploreDestinationCard from '../../ExploreDestination/ExploreDestinationCard';
import AdjustInterests from '../../AdjustInterests/AdjustInterests';
import SelectDestinationRedesign from '../../SelectDestinationRedesign/SelectDestinationRedesign';
import Priorities from '../../Priorities/Priorities';
import Button from '../../UI/Button/Button';
import { ACTIVITY_CATEGORY_OPTIONS } from '../../../constans/activity';
import ActivityType from '../../ActivityType/ActivityType';
import OtherFiltersItineraries from '../../OtherFiltersItineraries/OtherFiltersItineraries';
import BudgetCard from '../../Budget/BudgetCard';
import ActivityLoad from '../../ActivityLoad/ActivityLoad';
import {
  AccessibilityAndKidsOptions,
  FitnessRequirementsOptions,
} from '../../../constans/itineraries-search';
import DateInputRedesigned from '../../DateInputRedesigned/DateInputRedesigned';
import { filterObjectByKeys } from '../../../helpers/helper-methods';
import { ItinerarySearchProfileKeys } from '../../../constans/activity-search';
import itinerarySearchService from '../../../services/itinerarySearchService';
import { activitiesLoadInitialValues } from '../../../constans/activity-load';
import {
  BUDGET_PROPERTY,
  budgetFieldsInitialValues,
  getPriceRangeFromURL,
} from '../../../constans/budget';
import {
  budgetSchema,
  landingSearchAgeOfKidsValidationSchema,
} from '../../../constans/validation-schemas';
import LandingTabSpinner from '../../LandingTabSpinner/LandingTabSpinner';

import styles from './ItinerariesTab.module.scss';
import { STORAGE_DESTINATION } from '../../DestinationTree';
import { DATE_REQUIRED } from '../../../constans/error-messages';
import { getLocationFromURL } from '../../../helpers/getLocationFromUrl';
import { getFullLocationName } from '../../../helpers/generateLinks';

const validationSchema = object()
  .shape({
    checkin: string().required(DATE_REQUIRED),
  })
  .concat(landingSearchAgeOfKidsValidationSchema)
  .concat(budgetSchema);

function ItinerariesTab({
  handleSubmitForm,
  setTab,
  initValues,
  isMarketingPage = false,
  withInfo = false,
}) {
  const [destination, setDestination] = useState({});

  const [isSearching, setIsSearching] = useState(false);
  const setIsSearchingDebounced = (state) =>
    setTimeout(() => setIsSearching(state), 300);
  const [isDateError, setIsDateError] = useState(false);

  const searchDates = StorageService().get(LOCALSTORAGE_KEYS.SEARCH_DATES);

  const storageDestination = StorageService().get(STORAGE_DESTINATION);
  const minPrice = StorageService().get(
    LOCALSTORAGE_PRICE_KEYS.MIN_PRICE,
    null
  );
  const maxPrice = StorageService().get(
    LOCALSTORAGE_PRICE_KEYS.MAX_PRICE,
    null
  );

  const formInitData = useMemo(
    () => ({
      adults: initValues?.adults || 2,
      kids: initValues?.kids || 0,
      ageOfAdults: ['', ''],
      ageOfKids: [],
      categoriesFilters: [],
      accessibilityScore: AccessibilityAndKidsOptions[0].value,
      fitnessRequirementScore: FitnessRequirementsOptions[0].value,
      kidsAllowedScore: AccessibilityAndKidsOptions[0].value,
      checkin: searchDates?.checkin || initValues?.checkin || '',
      checkout: searchDates?.checkout || initValues?.checkout || '',
      ...getLocationFromURL(initValues),
      ...activitiesLoadInitialValues,
      ...budgetFieldsInitialValues,
      ...StorageService().get(LOCALSTORAGE_KEYS.ITINERARIES_SEARCH),
      ...getPriceRangeFromURL(initValues, minPrice, maxPrice),
    }),
    [searchDates]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSearchingDebounced(true);
    setSubmitting(true);

    let model = {};
    const {
      priceType,
      ageOfAdults,
      ageOfKids,
      categoriesFilters,
      checkin,
      checkout,
      // do not send this values
      adults,
      kids,
      locationName,
      currency,
      //
      ...restOfValues
    } = values;

    let userProfile = filterObjectByKeys(
      getSearchProfile(),
      ItinerarySearchProfileKeys
    );

    if (
      !userProfile.culturalAndDiscovery ||
      !userProfile.outdoorsAndPhysicalActivities ||
      !userProfile.beachAndSeaside ||
      !userProfile.entertainmentAndThemeParks ||
      !userProfile.cityDiscoveryAndShopping
    ) {
      userProfile = {
        ...activitySearchProfileInterestsInitialValues,
        ...userProfile,
      };
    }

    if (storageDestination?.country?.id || initValues?.countryId) {
      model.countryId = storageDestination?.country?.id || initValues.countryId;
    }
    if (storageDestination?.region?.id || initValues?.regionId) {
      model.regionId = storageDestination?.region?.id || initValues.regionId;
    }
    if (storageDestination?.city?.id || initValues?.cityId) {
      model.cityId = storageDestination?.city?.id || initValues.cityId;
    }
    if (storageDestination?.district?.id || initValues?.districtId) {
      model.districtId =
        storageDestination?.district?.id || initValues.districtId;
    }

    if (
      !userProfile.importanceOfPrice ||
      !userProfile.experienceIntensity ||
      !userProfile.comfortAndService ||
      !userProfile.locationAndAccess ||
      !userProfile.foodAndBeverages ||
      !userProfile.environmentalAndSocial
    ) {
      userProfile = {
        ...activitySearchProfilePriorityInitialValues,
        ...userProfile,
      };
    }

    // String required by bakcned
    model = {
      ...model,
      guestsAges: [...ageOfAdults, ...ageOfKids].map((v) => v.toString()),
      startDate: checkin,
      endDate: checkout,
      budget: priceType,
    };

    if (categoriesFilters.length) {
      model = { ...model, categoriesFilters };
    }

    const {
      data: { uuid },
    } = await itinerarySearchService().getSearchUUID({
      ...restOfValues,
      ...model,
      currency,
      userProfile,
    });

    const redirectQuery = qs.stringify({
      uuid,
      locationName,
      currency,
      kids,
      adults,
    });

    setSubmitting(false);
    StorageService().set(LOCALSTORAGE_KEYS.ITINERARIES_SEARCH, values);
    navigate(`/itineraries?${redirectQuery}`);
  };

  const handleSearch = (values, formikHelpers) => {
    handleSubmit(values, formikHelpers)
      //  .then(() => setIsSearchingDebounced(false)) //fixme: it causes a memory leak
      .catch(() => setIsSearchingDebounced(false));
  };

  const onClickFindTheBest = () => {
    setTab();
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onClickFindTheBestButton',
    });
  };

  return (
    <>
      <LandingTabSpinner show={isSearching} />
      <Formik
        enableReinitialize
        validateOnBlur
        validateOnChange
        initialTouched={{
          [BUDGET_PROPERTY.maxPrice.name]: true,
          [BUDGET_PROPERTY.minPrice.name]: true,
        }}
        onSubmit={handleSearch}
        initialValues={{
          ...formInitData,
          locationName: storageDestination?.country?.name
            ? getFullLocationName(storageDestination)
            : '',
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          setFieldValue,
          initialValues,
          isValid,
          setFieldTouched,
          touched,
        }) => {
          const changeMathValue = (field, value, direction) => {
            setFieldValue(field, direction ? value + 1 : value - 1);
          };

          StorageService().set(
            LOCALSTORAGE_PRICE_KEYS.MIN_PRICE,
            values.minPrice
          );
          StorageService().set(
            LOCALSTORAGE_PRICE_KEYS.MAX_PRICE,
            values.maxPrice
          );

          return (
            <Form>
              <div
                className={
                  isMarketingPage
                    ? styles.marketingTab
                    : styles.itinerariesSection
                }
              >
                <HomeTabSection className={styles.marketingTabSection}>
                  {!isMarketingPage && (
                    <HomeTabCard title="Destination">
                      <SelectDestinationRedesign
                        onSelect={setDestination}
                        classes={`${styles.wrapper}`}
                        isRegionRequired
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        initialValue={
                          initialValues?.country
                            ? {
                                name:
                                  initialValues?.locationName ||
                                  values?.locationName,
                                country: initialValues?.countryId || {
                                  id: values?.countryId,
                                },
                                region: initialValues?.regionId || {
                                  id: values?.regionId,
                                },
                                city: initialValues?.cityId || {
                                  id: values?.cityId,
                                },
                                district: initialValues?.districtId || {
                                  id: values?.districtId,
                                },
                              }
                            : {
                                name:
                                  getFullLocationName(storageDestination) ||
                                  values?.locationName,
                                country: storageDestination?.country || {
                                  id: values?.countryId,
                                },
                                region: storageDestination?.region || {
                                  id: values?.regionId,
                                },
                                city: storageDestination?.city || {
                                  id: values?.cityId,
                                },
                                district: storageDestination?.district || {
                                  id: values?.districtId,
                                },
                              }
                        }
                        error={errors.locationName}
                        touched={touched.locationName}
                      />
                    </HomeTabCard>
                  )}
                  <HomeTabCard
                    className={isMarketingPage ? styles.marketingTabCard : null}
                    contentClassName="w-100"
                    title="# Travellers"
                    divider={!isMarketingPage}
                  >
                    <GuestsInputRedesigned
                      setFieldValue={setFieldValue}
                      showErrors
                      errors={errors}
                      values={values}
                      changeMathValue={changeMathValue}
                      adultAgeInputName="ageOfAdults"
                      adultsCountInputName="adults"
                      kidsAgeInputName="ageOfKids"
                      kidsCountInputName="kids"
                      minAdultsCount={0}
                      setFieldTouched={setFieldTouched}
                    />
                  </HomeTabCard>
                  <HomeTabCard
                    className={isMarketingPage ? styles.marketingTabCard : null}
                    divider
                    title="Start & End Dates"
                  >
                    <DateInputRedesigned
                      setFieldValue={setFieldValue}
                      values={values}
                      calendarFields={['checkin', 'checkout']}
                      errors={errors}
                      setIsDateError={setIsDateError}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      saveToStore
                      untilTommorow
                    />
                  </HomeTabCard>
                  <BudgetCard
                    divider
                    values={values}
                    setFieldValue={setFieldValue}
                    className={isMarketingPage ? styles.marketingTabCard : null}
                    errors={errors}
                  />
                  <div
                    className={isMarketingPage ? styles.marketingTabCard : null}
                  >
                    <AdjustInterests
                      handleSubmit={handleSubmitForm}
                      inputs={activityRatingInterestInputs}
                      ageInputs={activityRatingAgeInterestOptions}
                      initialValues={
                        activitySearchProfileInterestsInitialValues
                      }
                      divider
                      hideHeader
                      tooltipField="comfortAndService"
                    />
                  </div>
                </HomeTabSection>
                {isMarketingPage && (
                  <Button
                    type="submit"
                    onClick={onClickFindTheBest}
                    disabled={!initValues?.name || !isValid || isDateError}
                    classes={`${styles.submitButton} ${styles.marketingSubmitButton} t-600 btn btn_primary-danger uppercased-text`}
                  >
                    Find The Best
                  </Button>
                )}
              </div>
              {!withInfo && (
                <ExploreDestinationCard
                  divider
                  activities
                  properties
                  handleSubmit={handleSubmitForm}
                  destination={destination}
                  categoriesFilters={values.categoriesFilters}
                  setTab={setTab}
                  storageDestination={storageDestination}
                />
              )}
              {!isMarketingPage && (
                <HomeTabSection
                  className={`${styles.filtersPanel} ${
                    withInfo && styles.withInfo
                  }`}
                >
                  <div className={`${styles.filtersButtonContainer}`}>
                    <Priorities
                      inputs={landingImportanceInputs}
                      initialValues={activitySearchProfilePriorityInitialValues}
                      handleSubmit={handleSubmitForm}
                    />
                    <ActivityType
                      inputs={ACTIVITY_CATEGORY_OPTIONS}
                      initialValues={{
                        categoriesFilters: initialValues?.categoriesFilters,
                      }}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                    <OtherFiltersItineraries
                      accessibilityOptions={AccessibilityAndKidsOptions}
                      fitnessOptions={FitnessRequirementsOptions}
                      initialValues={{
                        accessibilityScore: initialValues.accessibilityScore,
                        fitnessRequirementScore:
                          initialValues.fitnessRequirementScore,
                        kidsAllowedScore: initialValues.kidsAllowedScore,
                      }}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <ActivityLoad
                      values={values}
                      initialValues={initialValues}
                    />
                  </div>

                  <Button
                    type="submit"
                    onClick={onClickFindTheBest}
                    disabled={
                      !destination?.locationName || !isValid || isDateError
                    }
                    classes={`${styles.submitButton} t-600 btn btn_primary-danger uppercased-text`}
                  >
                    Find The Best
                  </Button>
                </HomeTabSection>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ItinerariesTab;
