import React, { useEffect, useState } from 'react';

import { Field } from 'formik';
import { ACTIVITIES_LOAD } from '../../constans/activity-load';
import FormikRadioGroup from '../RadioGroup/RadioGroup';

import styles from './ActivityLoad.module.scss';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';

function ActivityLoad({ values }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueCount, setValueCount] = useState(0);
  const [isDirty, setIsDirty] = useState(false);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const setBadgeCount = (formValues) => {
    const diffArray = Object.keys(values)
      .filter((key) => values[key] !== formValues[key]);
    setValueCount(diffArray.length);
    setIsDirty(diffArray.length > 0);
  };

  const onResetButtonClick = () => {
    Object.keys(values).forEach((key) => {
      values(key, values[key]);
    });
    setIsDirty(false);
  };

  const onSubmitButtonClick = (fieldValues) => {
    setBadgeCount(fieldValues);
    toggleModal();
  };

  useEffect(() => {
    setBadgeCount(values);
  }, [values]);

  return (
    <>
      <HomeTabCardModal
        title="Schedule"
        show={isModalOpen}
        displayToggle={toggleModal}
        onClickResetButton={onResetButtonClick}
        onClickSubmitButton={() => onSubmitButtonClick(values)}
        resetDisable={!isDirty}
        modalClassName={styles.modal}
      >

        <div className="flex-column col-12 pl-0">
          <div className={`flex-column col-12 ${styles.activityRow}`}>
            <p className={`${styles.activityRowTitle}`}>
              {ACTIVITIES_LOAD.dailyActivitiesLoad.label}
            </p>
            <Field
              name={ACTIVITIES_LOAD.dailyActivitiesLoad.name}
              defaultValue={
                    ACTIVITIES_LOAD.dailyActivitiesLoad.initialValue
                  }
              options={ACTIVITIES_LOAD.dailyActivitiesLoad.values}
              component={FormikRadioGroup}
              wrapperStyles="col-12 p-0"
              labelClassName={`${styles.activityRowLabel} col-12 col-sm-4`}
            />
          </div>
          <div className={`flex-column col-12 ${styles.activityRow}`}>
            <p className={`${styles.activityRowTitle}`}>
              {ACTIVITIES_LOAD.firstDayActivities.label}
            </p>
            <Field
              name={ACTIVITIES_LOAD.firstDayActivities.name}
              defaultValue={ACTIVITIES_LOAD.firstDayActivities.initialValue}
              options={ACTIVITIES_LOAD.firstDayActivities.values}
              component={FormikRadioGroup}
              wrapperStyles="col-12 p-0"
              labelClassName={`${styles.activityRowLabel} col-12 col-sm-4`}
            />
          </div>
          <div className={`flex-column col col-12 ${styles.activityRow}`}>
            <p className={`${styles.activityRowTitle}`}>
              {ACTIVITIES_LOAD.lastDayActivities.label}
            </p>
            <Field
              name={ACTIVITIES_LOAD.lastDayActivities.name}
              defaultValue={ACTIVITIES_LOAD.lastDayActivities.initialValue}
              options={ACTIVITIES_LOAD.lastDayActivities.values}
              component={FormikRadioGroup}
              wrapperStyles="col-12 p-0"
              labelClassName={`${styles.activityRowLabel} col-12 col-sm-4`}
            />
          </div>
        </div>

      </HomeTabCardModal>
      <LandingFiltersButton onClick={toggleModal} badgeText={valueCount}>
        Schedule
      </LandingFiltersButton>
    </>
  );
}
export default ActivityLoad;
