import React, { useEffect, useState } from 'react';

import { Field } from 'formik';
import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import FormikRadioGroup from '../RadioGroup/RadioGroup';

import styles from './OtherFiltersItineraries.module.scss';

const OtherFiltersItineraries = ({
  setFieldValue,
  values,
  initialValues,
  accessibilityOptions,
  fitnessOptions,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [valueCount, setValueCount] = useState(0);
  const [isDirty, setIsDirty] = useState(false);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const setBadgeCount = (formValues) => {
    const diffArray = Object.keys(initialValues)
      .filter((key) => initialValues[key] !== formValues[key]);
    setValueCount(diffArray.length);
    setIsDirty(diffArray.length > 0);
  };

  const onResetButtonClick = () => {
    Object.keys(initialValues).forEach((key) => {
      setFieldValue(key, initialValues[key]);
    });
    setIsDirty(false);
  };

  const onSubmitButtonClick = (fieldValues) => {
    setBadgeCount(fieldValues);
    toggleModal();
  };

  useEffect(() => {
    setBadgeCount(values);
  }, [values]);

  return (
    <>
      <HomeTabCardModal
        title="Other filters"
        show={isModalOpen}
        displayToggle={toggleModal}
        onClickResetButton={onResetButtonClick}
        onClickSubmitButton={() => onSubmitButtonClick(values)}
        resetDisable={!isDirty}
        modalClassName={styles.modal}
      >
        <>
          <div className={`${styles.sectionSubtitle} flex flex-row uppercased-text`}>
            Accessibility
            <InfoTooltip tooltipTextClassName={styles.tooltipText}>
              Refers to accessibility for visitors with reduced mobility
            </InfoTooltip>
          </div>
          <Field
            name="accessibilityScore"
            wrapperStyles={`${styles.radios}`}
            labelClassName={`col-sm-4 p-0 ${styles.radioLabel}`}
            options={accessibilityOptions}
            component={FormikRadioGroup}
          />
        </>
        <>
          <div className={`${styles.sectionSubtitle} uppercased-text`}>
            Fitness requirement
          </div>
          <Field
            name="fitnessRequirementScore"
            wrapperStyles={styles.radios}
            labelClassName={`col-sm-4 p-0 ${styles.radioLabel}`}
            options={fitnessOptions}
            component={FormikRadioGroup}
          />
        </>
        <>
          <div className={`${styles.sectionSubtitle} uppercased-text`}>
            Kids friendly
          </div>
          <Field
            name="kidsAllowedScore"
            wrapperStyles={styles.radios}
            labelClassName={`col-sm-4 p-0 ${styles.radioLabel}`}
            options={accessibilityOptions}
            component={FormikRadioGroup}
          />
        </>
      </HomeTabCardModal>

      <LandingFiltersButton onClick={toggleModal} badgeText={valueCount}>
        Other filters
      </LandingFiltersButton>
    </>
  );
};

export default OtherFiltersItineraries;
