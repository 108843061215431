import React, { useEffect, useRef, useState } from 'react';

import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import StorageService from '../../services/storage.service';
import UsersService from '../../services/usersService';
import Panel from '../Tabs/Panel';
import { Consumer, Provider } from '../Tabs/Context';
import Tabs from '../Tabs/Tabs';
import { isAuthGuard } from '../../guards/isAuthGuard';
import ItinerariesTab from '../Home/ItinerariesTab/ItinerariesTab';
import ActivityTab from '../Home/ActivityTab/ActivityTab';
import StaysTab from '../Home/StaysTab/StaysTab';
import { TabTypes } from '../../constans/urlParams';
import { dayInMilliSeconds } from '../../constans/datepicker';

const storageService = StorageService();

const getUser = () => storageService.get(LOCALSTORAGE_KEYS.USER, {});

const getTab = () => storageService.get(LOCALSTORAGE_KEYS.HOME_TAB, 0);
const setTab = (tabId) => {
  storageService.set(LOCALSTORAGE_KEYS.HOME_TAB, tabId);
};

function ExploreTab({ styles, withInfo = false }) {
  const fields = ['Itineraries', 'Activities', 'Stays'];
  const [scroll, setScroll] = useState({ left: 0, right: 9999 });

  const onTabsScroll = (event) => {
    const { scrollLeft, clientWidth, scrollWidth } = event.currentTarget;

    setScroll(() => ({
      left: scrollLeft,
      right: scrollWidth - scrollLeft - clientWidth,
    }));
  };

  const itemsRef = useRef(null);

  useEffect(() => {
    itemsRef?.current?.addEventListener('scroll', onTabsScroll);

    return () => {
      itemsRef?.current?.removeEventListener('scroll', onTabsScroll);
    };
  }, [itemsRef.current]);

  const handleProfileChanges = async (values) => {
    const isAuth = isAuthGuard();

    if (!isAuth) {
      storageService.set(LOCALSTORAGE_KEYS.USER, {
        ...getUser(),
        searchProfile: values,
      });
      return;
    }

    const { data } = await UsersService().updateSearchProfile(values);

    const {
      // eslint-disable-next-line no-unused-vars
      id,
      createdAt,
      updatedAt,
      ...searchProfile
    } = data;

    storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser(), searchProfile });
  };

  const handleStaySubmit = (values) => {
    handleProfileChanges(values);
  };

  const handleActivitiesSubmit = (values) => {
    handleProfileChanges(values);
  };

  const handleItinerariesSubmit = (values) => {
    handleProfileChanges(values);
  };

  let urlParams = {};

  if (typeof window !== 'undefined') {
    // return window?.location?.hostname || document?.location?.hostname;
    const params = new URLSearchParams(window.location.search.slice(1));
    urlParams = Object.fromEntries(params);
    if (!urlParams.checkin) {
      urlParams.checkin = new Date(Date.now() + 7 * dayInMilliSeconds);
    }
    if (!urlParams.checkout) {
      urlParams.checkout = new Date(Date.now() + 9 * dayInMilliSeconds);
    }
    if (urlParams?.type) {
      setTab(TabTypes[urlParams.type]);
    }
  }

  return (
    <div className={`${styles.card}`}>
      <Provider defaultActiveIndex={getTab()}>
        <Consumer>
          {() => (
            <Tabs
              switchers={fields}
              switchersClassName={`${styles.switcher} p-0 ${styles.switcher__flexCenter}`}
              switchButtonClassName={styles.switch}
              activeClassName={styles.active}
              activeIndex={2}
              before={
                <div
                  className={`${styles.arrow} ${styles.arrowLeft} ${
                    scroll.left > 5 && styles.arrowShown
                  }`}
                >
                  <svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.26863 6.4L0.234315 1.36569C-0.0781048 1.05327 -0.0781048 0.546734 0.234315 0.234315C0.546734 -0.0781048 1.05327 -0.0781048 1.36569 0.234315L6.96568 5.83431C7.2781 6.14673 7.2781 6.65327 6.96568 6.96568L1.36569 12.5657C1.05327 12.8781 0.546734 12.8781 0.234315 12.5657C-0.0781048 12.2533 -0.0781048 11.7467 0.234315 11.4343L5.26863 6.4Z"
                      fill="#ffffff"
                    />
                  </svg>
                </div>
              }
              after={
                <div
                  className={`${styles.arrow} ${styles.arrowRight} ${
                    scroll.right > 5 && styles.arrowShown
                  }`}
                >
                  <svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.26863 6.4L0.234315 1.36569C-0.0781048 1.05327 -0.0781048 0.546734 0.234315 0.234315C0.546734 -0.0781048 1.05327 -0.0781048 1.36569 0.234315L6.96568 5.83431C7.2781 6.14673 7.2781 6.65327 6.96568 6.96568L1.36569 12.5657C1.05327 12.8781 0.546734 12.8781 0.234315 12.5657C-0.0781048 12.2533 -0.0781048 11.7467 0.234315 11.4343L5.26863 6.4Z"
                      fill="#ffffff"
                    />
                  </svg>
                </div>
              }
              ref={itemsRef}
            >
              <div className={`${styles.cardBody}`}>
                <Panel tabId={0}>
                  <ItinerariesTab
                    handleSubmitForm={handleItinerariesSubmit}
                    setTab={() => setTab(0)}
                    initValues={urlParams}
                    withInfo={withInfo}
                  />
                </Panel>
                <Panel tabId={1}>
                  <ActivityTab
                    handleSubmitForm={handleActivitiesSubmit}
                    setTab={() => setTab(1)}
                    initValues={urlParams}
                    withInfo={withInfo}
                  />
                </Panel>
                <Panel tabId={2}>
                  <StaysTab
                    handleSubmitForm={handleStaySubmit}
                    setTab={() => setTab(2)}
                    initValues={urlParams}
                    isModal={withInfo}
                    withInfo={withInfo}
                  />
                </Panel>
              </div>
            </Tabs>
          )}
        </Consumer>
      </Provider>
    </div>
  );
}

export default ExploreTab;
