import { createRadioOptionsFromObject } from '../helpers/helper-methods';

const DAILY_ACTIVITIES = {
  LIGHT: 'Light (6-7h)',
  MODERATE: 'Moderate (8-9h)',
  FULL: 'Full (10-11h)',
};

const FIRST_DAY_ACTIVITIES = {
  NONE: 'None',
  AFTERNOON: 'Afternoon',
  FULL_DAY: 'Full day',
};

const LAST_DAY_ACTIVITIES = {
  NONE: 'None',
  MORNING: 'Morning',
  FULL_DAY: 'Full day',
};

const dailyActivitiesLoad = {
  name: 'dailyLoadOfActivities',
  label: 'Daily load of activities',
  initialValue: 'MODERATE',
  values: createRadioOptionsFromObject(DAILY_ACTIVITIES),
};

const firstDayActivities = {
  name: 'activitiesOnFirstDay',
  label: 'Activities on first day',
  initialValue: 'FULL_DAY',
  values: createRadioOptionsFromObject(FIRST_DAY_ACTIVITIES),
};

const lastDayActivities = {
  name: 'activitiesOnLastDay',
  label: 'Activities on last day',
  initialValue: 'FULL_DAY',
  values: createRadioOptionsFromObject(LAST_DAY_ACTIVITIES),
};

export const ACTIVITIES_LOAD = {
  dailyActivitiesLoad,
  firstDayActivities,
  lastDayActivities,
};

export const DAILY_ACTIVITIES_SHORTCUT = {
  LIGHT: 'Light',
  MODERATE: 'Moderate',
  FULL: 'Full',
};

export const FIRST_DAY_ACTIVITIES_SHORTCUT = {
  NONE: 'N',
  AFTERNOON: 'H',
  FULL_DAY: 'F',
};

export const LAST_DAY_ACTIVITIES_SHORTCUT = {
  NONE: 'N',
  MORNING: 'H',
  FULL_DAY: 'F',
};

export const activitiesLoadInitialValues = Object.keys(ACTIVITIES_LOAD).reduce(
  (acc, curr) => ({
    ...acc,
    [ACTIVITIES_LOAD[curr].name]: ACTIVITIES_LOAD[curr].initialValue,
  }),
  {},
);
