import React, { useCallback, useEffect, useState } from 'react';

import HomeTabCardModal from '../Home/HomeTabCardModal/HomeTabCardModal';
import LandingFiltersButton from '../Home/FiltersLandingButton/LandingFiltersButton';
import { AllOptionActivityType } from '../../constans/activity';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';

import styles from './ActivityType.module.scss';

const ActivityType = ({
  inputs,
  initialValues,
  values,
  setFieldValue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtersCount, setFiltersCount] = useState(0);
  const [isFull, setIsFull] = useState(false);

  const toggleModal = () => (
    setIsModalOpen((prev) => !prev)
  );

  const setBadgeCount = (formValues) => {
    let count = 0;
    Object.keys(initialValues).forEach((key) => {
      setIsFull(formValues[key].length === AllOptionActivityType.value.length);
      count += formValues[key].length;
    });
    if (count === 0 || count === AllOptionActivityType.value.length) {
      setFiltersCount(0);
    } else {
      setFiltersCount(count);
    }
  };

  const onResetButtonClick = useCallback(() => {
    Object.keys(initialValues)
      .forEach((key) => {
        setFieldValue(key, isFull ? [] : AllOptionActivityType.value);
      });
    setIsFull(false);
  }, [isFull]);

  const onSubmitButtonClick = () => {
    setBadgeCount(values);
    toggleModal();
  };

  useEffect(() => {
    setBadgeCount(values);
  }, [values]);

  return (
    <>
      <HomeTabCardModal
        title="Activity type"
        show={isModalOpen}
        displayToggle={toggleModal}
        onClickResetButton={onResetButtonClick}
        onClickSubmitButton={onSubmitButtonClick}
        resetDisable={false}
        secondaryButtonName={isFull ? 'Reset all' : 'Select all'}
      >
        <div className={`${styles.checkboxesContainer}`}>
          <CheckboxGroup
            name="categoriesFilters"
            valueField="value"
            checkboxClassName={styles.checkbox}
            wrapperClassName={`${styles.checkboxDropdowned} col-12 col-sm-6`}
            titleClassName={`${styles.checkboxText} ${styles.label}`}
            options={inputs}
          />
        </div>
      </HomeTabCardModal>
      <LandingFiltersButton
        onClick={toggleModal}
        badgeText={filtersCount}
      >
        Activity type
      </LandingFiltersButton>
    </>
  );
};

export default ActivityType;
