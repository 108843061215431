import { LOCATION_VIEW } from '../constans/locations';

const locationData = ['locationName', 'countryId', 'regionId', 'cityId', 'districtId'];
export const getLocationFromURL = (initValues) => {
  const location = {};
  locationData.forEach((item) => {
    if (initValues[item]) {
      location[item] = initValues[item];
    }
  });
  return location;
};

export const getTitle = (locationView) => {
  if (locationView === LOCATION_VIEW.COUNTRY) {
    return 'regions in';
  }

  if (locationView === LOCATION_VIEW.REGION) {
    return 'cities in';
  }

  return 'districts in';
};
